import React, { Component } from 'react'
// import { Trans, withTranslation } from 'react-i18next'
import { ConfigProvider, Drawer, Input, Form, Upload, Button, Result } from 'antd'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { enquireScreen } from 'enquire-js'
import { removeURLParameter, showAntMessage, validateEmail } from './utils'
import {
  getUserInfoAPI,
  imgUploaderURL,
  recaptchaSiteKeyV2,
  submitFeedbackAPI,
  uploadPictureAPI
} from './apis'
import debounce from 'lodash/debounce'
import ReCAPTCHA from 'react-google-recaptcha'
import './css/GlobalFeedback.scss'

const saveFormKeyName = 'vietnovel-feedback-form'
const Field = Form.Item
const { TextArea } = Input
const layout = {
  labelCol: {
    span: 4
  },
  wrapperCol: {
    span: 20
  }
}
const tailLayout = {
  wrapperCol: {
    offset: 4,
    span: 20
  }
}

class GlobalFeedback extends Component {
  constructor (props) {
    super(props)
    this.containerRef = React.createRef()
    this.form = null
  }

  state = {
    isMobile: null,
    open: false,
    files: [],
    userInfo: {},
    pictures: [],
    loading: false,
    success: false
  }

  componentDidMount () {
    enquireScreen(mobile => {
      this.setState({
        isMobile: mobile
      })
    })
  }

  componentWillUnmount () {
    clearAllBodyScrollLocks()
    window.sessionStorage.removeItem(saveFormKeyName)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.form && prevState.userInfo.username !== this.state.userInfo.username) {
      this.form.resetFields()
    }
    if (prevState.open !== this.state.open) {
      if (this.state.open) {
        disableBodyScroll(this.containerRef.current.querySelector('.vietnovel-drawer-body'))
      } else {
        clearAllBodyScrollLocks()
      }
    }
  }

  afterVisibleChange = visible => {
    if (visible) {
      getUserInfoAPI().then(r => {
        this.setState({ userInfo: r.data })
      })
    }
  }

  onClose = () => {
    this.setState({ open: false, success: false, loading: false })
    window.sessionStorage.removeItem(saveFormKeyName)
  }

  onFinish = values => {
    this.setState({ loading: true })
    const params = {
      ...values,
      objImg: this.state.pictures,
      'recaptcha_v2': true
    }
    submitFeedbackAPI(params).then(r => {
      this.setState({ loading: false })
      if (r.data.ok) {
        window.sessionStorage.removeItem(saveFormKeyName)
        this.setState({ files: [], success: true })
      } else {
        showAntMessage.error(r.data.msg)
      }
    })
  }

  onFieldsChange = (changedFields, allFields) => {
    this.saveForm(this.form.getFieldsValue())
  }

  saveForm = debounce((data) => {
    window.sessionStorage.setItem(saveFormKeyName, JSON.stringify(data))
  }, 500)

  onUploaderRemove = (file) => {
    this.setState(state => {
      const index = state.files.indexOf(file)
      const newFileList = state.files.slice()
      newFileList.splice(index, 1)
      return {
        files: newFileList
      }
    })
    let pictures = this.state.pictures.slice(0)
    pictures.splice(pictures.findIndex(t => t.uid === file.uid), 1)
    this.setState({
      pictures
    })
  }

  onUploaderChange = (info) => {
    const { file, fileList } = info

    if (fileList.length > 8) {
      showAntMessage.error('Chỉ có thể lựa chọn tối đa 8 hình ảnh')
      this.setState({ files: this.state.files })
      return
    }
    if (file.status !== 'removed' && fileList.length <= 5) {
      const i = this.state.files.findIndex(t => t.name === file.name && t.size === file.size && t.type === file.type)
      if (i === -1) this.upload(file)
    }
    let a_ = fileList.slice(0)
    const x = a_.filter((item, idx, self) => {
      return idx === self.findIndex((t) => (
        t.name === item.name && t.size === item.size && t.type === item.type
      ))
    })
    const y = x.slice(0, 5)
    this.setState({
      files: y
    })
  }

  beforeUpload =(file) => {
    this.setState(({
      files: [...this.state.files, file]
    }))
    return false
  }

  upload = (file) => {
    this.setState({ loading: true })
    // eslint-disable-next-line
    const formData = new FormData()
    formData.append('file', file, file.name)
    uploadPictureAPI(formData).then(res => {
      const data = res.data
      if (data.ok) {
        const i = {
          verifyCode: data.verifyCode,
          url: data.image.url,
          uid: file.uid
        }
        let a_ = this.state.pictures.slice(0)
        a_.push(i)
        // remove duplicate https://stackoverflow.com/a/36744732/186153
        const pictures = a_.filter((item, index, self) => {
          return index === self.findIndex((t) => (
            t.verifyCode === item.verifyCode
          ))
        })
        this.setState({
          pictures,
          loading: false
        })
      } else {
        showAntMessage.error(data.msg)
      }
    })
  }

  render () {
    const { isMobile, files, userInfo, open, loading, success } = this.state

    const initVal = () => {
      const saved = window.sessionStorage.getItem(saveFormKeyName)
      if (saved) {
        try {
          return JSON.parse(saved)
        } catch (e) {
        }
      }
      return {
        name: userInfo.name || userInfo.username,
        email: userInfo.email,
        link: removeURLParameter(window.location.href, 'feedback')
      }
    }

    return (
      <ConfigProvider prefixCls='vietnovel'>
        <div ref={this.containerRef} className='GlobalFeedback'>
          {!isMobile && (
            <>
              <button className='GlobalFeedback-button' onClick={() => this.setState({ open: true })}>PHẢN HỒI</button>
              {open && (
                <Drawer
                  title='Gửi Lời Nhắn Đến Quản Trị Viên'
                  placement='right'
                  closable
                  onClose={this.onClose}
                  visible={open}
                  width='800px'
                  getContainer={false}
                  afterVisibleChange={this.afterVisibleChange}
                  zIndex={10001}
                >
                  {!success ? (
                    <Form
                      {...layout}
                      ref={form => { this.form = form }}
                      style={{ overflow: 'auto' }}
                      name='desktop-global-feedback'
                      onFinish={this.onFinish}
                      onFieldsChange={this.onFieldsChange}
                      initialValues={initVal()}
                    >
                      <Field
                        label='Họ Tên'
                        name='name'
                        rules={[
                          {
                            required: true,
                            message: 'Trường họ tên là bắt buộc'
                          }
                        ]}
                      >
                        <Input
                          type='text'
                          placeholder='Tên gọi của bạn'
                          maxLength={100}
                          data-lpignore='true'
                        />
                      </Field>

                      <Field
                        label='Email'
                        name='email'
                        rules={[
                          {
                            required: true,
                            message: 'Email là bắt buộc'
                          },
                          {
                            validator: (rule, value) => {
                              if (validateEmail(value)) {
                                return Promise.resolve()
                              }
                              return Promise.reject(Error('Vui lòng nhập địa chỉ email hợp lệ!'))
                            }
                          }
                        ]}
                      >
                        <Input
                          type='email'
                          placeholder='name@domain.com'
                          maxLength={200}
                          data-lpignore='true'
                        />
                      </Field>

                      <Field
                        label='Liên Kết'
                        name='link'
                        rules={[
                          {
                            required: true,
                            message: 'Liên kết là bắt buộc'
                          }
                        ]}
                      >
                        <Input
                          type='url'
                          placeholder='Referral URL'
                          maxLength={1000}
                          data-lpignore='true'
                        />
                      </Field>

                      <Field
                        label='Lời Nhắn'
                        name='desc'
                        rules={[
                          {
                            required: true,
                            message: 'Trường lời nhắn là bắt buộc'
                          }
                        ]}
                      >
                        <TextArea
                          rows={4}
                          placeholder='Vui lòng mô tả chi tiết vấn đề để được giải quyết nhanh hơn'
                          data-lpignore='true'
                        />
                      </Field>

                      <Field
                        {...tailLayout}
                      >
                        <Upload
                          action={imgUploaderURL}
                          accept='image/*'
                          listType='picture'
                          fileList={files}
                          disabled={loading}
                          multiple
                          beforeUpload={this.beforeUpload}
                          onRemove={this.onUploaderRemove}
                          onChange={this.onUploaderChange}
                        >
                          <Button>
                            <i className='fa fa-upload' /> Tải Lên Hình Ảnh
                          </Button>
                        </Upload>
                      </Field>
                      <Field{...tailLayout} className='mt-n3'>
                        <div className='text-muted text-sm'>Bạn có thể tải lên các hình ảnh mô tả vấn đề hoặc lỗi. Tối đa 8 ảnh cho mỗi lần báo cáo.</div>
                      </Field>

                      <Field
                        {...tailLayout}
                        name='token'
                        rules={[
                          {
                            required: true,
                            message: 'Vui lòng check vào ô kiểm của captcha để tiếp tục'
                          }
                        ]}
                      >
                        <ReCAPTCHA
                          sitekey={recaptchaSiteKeyV2}
                          hl='vi'
                        />
                      </Field>

                      <Field {...tailLayout}>
                        <Button type='primary' loading={loading} htmlType='submit'>
                          <span className={loading ? 'd-none' : ''}><i className='fas fa-check' /></span>
                          <span className='ml-2'>Gửi Lời Nhắn</span>
                        </Button>
                        <Button className='ml-2' onClick={this.onClose}>Bỏ qua</Button>
                      </Field>
                    </Form>
                  ) : (
                    <Result
                      status='success'
                      title='Cảm ơn phản hồi của bạn'
                      subTitle='Chúng tôi sẽ ghi nhận và sẽ cải thiện để ứng dụng ngày càng tốt hơn.'
                      extra={[
                        <Button key='close' onClick={this.onClose}>Hoàn Tất</Button>
                      ]}
                    />
                  )}
                </Drawer>
              )}
            </>
          )}
        </div>
      </ConfigProvider>
    )
  }
}

export default GlobalFeedback
