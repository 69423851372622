import axios from 'axios'
// Polyfill Promises for IE and older browsers.
require('es6-promise').polyfill()

/* eslint-disable */
let urlPrefix = `http://localhost:8000`

// export const recaptchaSiteKeyV2        = window.__RECAPTCHA_SITE_KEY_V2__ || '6Lc7jNUUAAAAAJcbFMMM4p3wewOmVECbkO1f37aZ'
export const recaptchaSiteKeyV2        = window.__RECAPTCHA_SITE_KEY_V2__ || '6Le4IB8UAAAAAJMUBlb1HQeSwYTMQ835wKm4Z62e'

export const globalFeedback            = window.__GLOBAL_FEEDBACK__ || {}
export const namespaceFeedback         = globalFeedback.namespace || 'thien_ns'
export const imgUploaderURL            = globalFeedback.uploaderURL || 'https://img.vnvl.net/upload/'
export const submitFeedbackURL         = globalFeedback.submitURL || `${urlPrefix}/web-api/feedback/send/`
export const getUserInfoURL            = globalFeedback.userInfoURL || `${urlPrefix}/web-api/feedback/user-info/`
/* eslint-enable */

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.withCredentials = true

export function submitFeedbackAPI (params) {
  return axios.post(submitFeedbackURL, params)
}

export function getUserInfoAPI () {
  return axios.get(getUserInfoURL)
}

export function uploadPictureAPI (formData) {
  formData.append('namespace', namespaceFeedback)
  const config = {
    timeout: 10000,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    withCredentials: false
  }
  return axios.post(imgUploaderURL, formData, config)
}
